import React, { useState } from 'react';
import { Box } from '@mui/material';
import HomepageV4 from '../../components/home/HomepageV4';
import SignupModal from '../../components/common/SignupModal';

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenSignupModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseSignupModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <HomepageV4 onOpenSignupModal={handleOpenSignupModal} />
      <SignupModal
        open={isModalOpen}
        onClose={handleCloseSignupModal}
      />
    </Box>
  );
};

export default HomePage;