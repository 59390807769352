import React, { useState, useEffect } from 'react';
import { Camera, Zap, Shield, Sparkles, ArrowRight, Menu, X, Check, Download, Play, Quote, Users, Timer, ChevronDown, ChevronUp, Target, TrendingUp, LineChart, UserCheck } from 'lucide-react';
import { useForm, ValidationError } from '@formspree/react';

const HomepageV4 = ({ onOpenSignupModal }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeUsers, setActiveUsers] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [openFaq, setOpenFaq] = useState(null);
  const [showThankYou, setShowThankYou] = useState(false);
  const [state, handleSubmit] = useForm("mgvwvqvw");

  const faqs = [
    {
      question: "How does truepipe.ai identify competitor contacts?",
      answer: "truepipe.ai monitors the LinkedIn connections of your competitors' sales teams in real-time. When their BDRs, SDRs, or AEs connect with new prospects, you'll receive instant notifications through Slack, giving you valuable insights into their prospecting activities."
    },
    {
      question: "Is it legal to track competitor connections?",
      answer: "Yes, truepipe.ai only tracks publicly available information from LinkedIn profiles. We adhere to all LinkedIn's terms of service and privacy policies, focusing solely on public connection data that sales professionals share openly."
    },
    {
      question: "How accurate is the contact identification?",
      answer: "Our system maintains a 99.9% accuracy rate in identifying new connections. We use advanced algorithms to filter out non-prospect connections (like internal employees or vendors) to ensure you only receive relevant prospect notifications."
    },
    {
      question: "Can I integrate truepipe.ai with my CRM?",
      answer: "Currently, we offer direct integration with Slack for real-time notifications. CRM integrations with Salesforce, HubSpot, and other major platforms are on our roadmap and will be available in upcoming releases."
    },
    {
      question: "How quickly do I receive notifications about new connections?",
      answer: "Notifications are sent in real-time through Slack as soon as new connections are detected. For Enterprise customers, we also provide customizable digest reports at your preferred intervals (daily, weekly, or monthly)."
    },
    {
      question: "What kind of onboarding support do you provide?",
      answer: "All plans include full onboarding support. Our customer success team will help you set up competitor tracking, configure Slack notifications, and train your team on best practices. Enterprise customers receive additional dedicated support and custom workflow optimization."
    }
  ];

  const pricingPlans = [
    {
      name: "Starter",
      price: "$499",
      period: "/month",
      description: "Perfect for small teams getting started",
      features: [
        "Monitor up to 3 competitors",
        "Track up to 10 sales reps",
        "Real-time Slack notifications",
        "Basic email support",
        "Weekly summary reports"
      ],
      cta: "Start Free Trial",
      popular: false
    },
    {
      name: "Pro",
      price: "$999",
      period: "/month",
      description: "Best for growing sales teams",
      features: [
        "Monitor up to 10 competitors",
        "Track up to 50 sales reps",
        "Real-time Slack notifications",
        "Priority email support",
        "Daily summary reports",
        "Custom alert settings",
        "Export capabilities"
      ],
      cta: "Start Free Trial",
      popular: true
    },
    {
      name: "Enterprise",
      price: "Custom",
      period: "",
      description: "For large organizations",
      features: [
        "Unlimited competitor tracking",
        "Unlimited sales rep tracking",
        "Real-time Slack notifications",
        "24/7 priority support",
        "Custom reporting",
        "API access",
        "Dedicated account manager",
        "Custom integrations"
      ],
      cta: "Contact Sales",
      popular: false
    }
  ];

  const handleTalkToSales = () => {
    onOpenSignupModal();
  };

  useEffect(() => {
    if (state.succeeded) {
      setEmail('');
      setShowThankYou(true);
      // Hide thank you message after 5 seconds
      setTimeout(() => {
        setShowThankYou(false);
      }, 5000);
    }
  }, [state.succeeded]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-purple-50/5 to-blue-50/5">
      {/* Social Proof Banner */}
      <div className="bg-gradient-to-r from-purple-600 to-blue-600 text-white py-2">
        <div className="max-w-7xl mx-auto px-4 flex items-center justify-center gap-2 text-sm">
          <Users className="w-4 h-4" />
          <span><b>{2453 + activeUsers}</b> professionals using truepipe.ai right now</span>
          <Timer className="w-4 h-4 ml-4" />
          <span>Limited time offer: <b>50% off Pro Plan</b></span>
        </div>
      </div>

      {/* Navigation */}
      <nav className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-lg border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center gap-2">
              <Target className="w-6 h-6 text-purple-600" />
              <span className="font-bold text-gray-900">truepipe.ai</span>
            </div>
            
            <div className="hidden md:flex items-center gap-8">
              <a href="#features" className="text-gray-600 hover:text-gray-900">How It Works</a>
              <a href="#testimonials" className="text-gray-600 hover:text-gray-900">Testimonials</a>
              <a href="#pricing" className="text-gray-600 hover:text-gray-900">Pricing</a>
              <a 
                href="https://calendly.com/truepipe/30min" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="px-4 py-2 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-full font-medium hover:opacity-90 transition-opacity"
              >
                Talk to Sales
              </a>
            </div>

            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden">
              {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <a href="#features" className="block px-3 py-2 text-gray-600">Features</a>
              <a href="#testimonials" className="block px-3 py-2 text-gray-600">Testimonials</a>
              <a href="#pricing" className="block px-3 py-2 text-gray-600">Pricing</a>
              <a 
                href="https://calendly.com/truepipe/30min"
                target="_blank"
                rel="noopener noreferrer"
                className="block w-full mt-2 px-4 py-2 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-full font-medium text-center"
              >
                Talk to Sales
              </a>
            </div>
          </div>
        )}
      </nav>

      {/* Hero Section */}
      <div className="relative pt-32 pb-20 px-4">
        <div className="absolute inset-0 bg-white"></div>
        <div className="max-w-7xl mx-auto text-center relative z-10">
          <div className="flex items-center justify-center gap-2 mb-4">
            <span className="px-4 py-1 bg-purple-100 text-purple-600 rounded-full text-sm font-medium">
              Trusted by 100+ companies
            </span>
          </div>
          <h1 className="text-5xl md:text-7xl font-bold mb-6">
            <div className="gradient-text inline-block">Contact</div>{" "}
            <div className="gradient-text inline-block">Level</div>
            <br />
            <span className="text-gray-900">Competitive Intelligence</span>
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Identify who's talking to your competitors. Push their LinkedIn profiles to Slack in real-time. 
          </p>
          
          {/* Email Capture Form */}
          <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-4 justify-center items-center mb-8">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your work email"
              className="px-6 py-4 rounded-full border border-gray-200 w-full sm:w-96 focus:outline-none focus:ring-2 focus:ring-purple-600"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <ValidationError 
              prefix="Email" 
              field="email"
              errors={state.errors}
              className="text-red-500 text-sm mt-1"
            />
            <button 
              type="submit"
              disabled={state.submitting}
              className="px-8 py-4 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-full font-medium hover:opacity-90 transition-opacity flex items-center gap-2 premium-shadow w-full sm:w-auto disabled:opacity-50"
            >
              <Download className="w-5 h-5" /> 7-Day Free Trial
            </button>
          </form>

          {/* Thank You Message */}
          {showThankYou && (
            <div className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center z-50">
              <div className="bg-white rounded-lg shadow-xl p-8 max-w-md mx-4 animate-fade-in">
                <div className="text-center">
                  <Check className="w-12 h-12 text-green-500 mx-auto mb-4" />
                  <h3 className="text-xl font-bold mb-2">Thanks for joining our waitlist!</h3>
                  <p className="text-gray-600 mb-4">We'll be in touch soon with next steps.</p>
                  <div className="border-t border-gray-100 pt-4">
                    <p className="text-sm text-gray-500 mb-2">Want to talk sooner?</p>
                    <a 
                      href="https://calendly.com/truepipe/30min"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-block px-4 py-2 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-full text-sm font-medium hover:opacity-90 transition-opacity"
                    >
                      Schedule a Call
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Social Proof Metrics */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 max-w-3xl mx-auto mb-16">
            <div className="text-center">
              <h3 className="text-3xl font-bold text-gray-900">100+</h3>
              <p className="text-gray-600">Active Users</p>
            </div>
            <div className="text-center">
              <h3 className="text-3xl font-bold text-gray-900">4.9/5</h3>
              <p className="text-gray-600">User Rating</p>
            </div>
            <div className="text-center">
              <h3 className="text-3xl font-bold text-gray-900">10,000+</h3>
              <p className="text-gray-600">Contacts Identified</p>
            </div>
            <div className="text-center">
              <h3 className="text-3xl font-bold text-gray-900">92%</h3>
              <p className="text-gray-600">Satisfaction</p>
            </div>
          </div>

          {/* Demo Preview with Play Button Overlay */}
          <div className="relative group cursor-pointer" onClick={() => setIsModalOpen(true)}>
            <div className="absolute inset-0 bg-gradient-to-b from-white via-transparent to-white pointer-events-none"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-20 h-20 bg-white rounded-full flex items-center justify-center shadow-xl group-hover:scale-110 transition-transform">
                <Play className="w-8 h-8 text-purple-600 ml-1" />
              </div>
            </div>
            <img 
              src="https://images.unsplash.com/photo-1593720213428-28a5b9e94613?w=1200&h=800&fit=crop&q=80" 
              alt="LeadLinker.ai Interface" 
              className="rounded-2xl shadow-2xl premium-shadow mx-auto"
            />
          </div>
        </div>
      </div>

      {/* Usage Statistics Section */}
      <div className="py-20 bg-gradient-to-b from-purple-50/10 to-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Proven Results</h2>
            <p className="text-xl text-gray-600">Real statistics from our customer base</p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-2xl blur-xl opacity-50 group-hover:opacity-100 transition-opacity"></div>
              <div className="relative glass-effect p-8 rounded-2xl h-full premium-shadow group-hover:bg-white/95 transition-all">
                <div className="w-12 h-12 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-xl flex items-center justify-center mb-6 mx-auto group-hover:scale-110 transition-transform">
                  <Target className="w-6 h-6 text-white" />
                </div>
                <h3 className="text-4xl font-bold text-center mb-2 bg-gradient-to-r from-indigo-500 to-purple-500 bg-clip-text text-transparent">80%+</h3>
                <p className="text-gray-600 text-center">of new connections are prospects in new sales cycles</p>
              </div>
            </div>

            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-2xl blur-xl opacity-50 group-hover:opacity-100 transition-opacity"></div>
              <div className="relative glass-effect p-8 rounded-2xl h-full premium-shadow group-hover:bg-white/95 transition-all">
                <div className="w-12 h-12 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-xl flex items-center justify-center mb-6 mx-auto group-hover:scale-110 transition-transform">
                  <TrendingUp className="w-6 h-6 text-white" />
                </div>
                <h3 className="text-4xl font-bold text-center mb-2 bg-gradient-to-r from-blue-500 to-cyan-500 bg-clip-text text-transparent">3.5x</h3>
                <p className="text-gray-600 text-center">increase in pipeline generation after implementation</p>
              </div>
            </div>

            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-violet-500 to-fuchsia-500 rounded-2xl blur-xl opacity-50 group-hover:opacity-100 transition-opacity"></div>
              <div className="relative glass-effect p-8 rounded-2xl h-full premium-shadow group-hover:bg-white/95 transition-all">
                <div className="w-12 h-12 bg-gradient-to-r from-violet-500 to-fuchsia-500 rounded-xl flex items-center justify-center mb-6 mx-auto group-hover:scale-110 transition-transform">
                  <LineChart className="w-6 h-6 text-white" />
                </div>
                <h3 className="text-4xl font-bold text-center mb-2 bg-gradient-to-r from-violet-500 to-fuchsia-500 bg-clip-text text-transparent">45%</h3>
                <p className="text-gray-600 text-center">reduction in sales cycle length</p>
              </div>
            </div>

            <div className="relative group">
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500 to-pink-500 rounded-2xl blur-xl opacity-50 group-hover:opacity-100 transition-opacity"></div>
              <div className="relative glass-effect p-8 rounded-2xl h-full premium-shadow group-hover:bg-white/95 transition-all">
                <div className="w-12 h-12 bg-gradient-to-r from-purple-500 to-pink-500 rounded-xl flex items-center justify-center mb-6 mx-auto group-hover:scale-110 transition-transform">
                  <UserCheck className="w-6 h-6 text-white" />
                </div>
                <h3 className="text-4xl font-bold text-center mb-2 bg-gradient-to-r from-purple-500 to-pink-500 bg-clip-text text-transparent">92%</h3>
                <p className="text-gray-600 text-center">customer satisfaction rate</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features */}
      <div id="features" className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">How It Works</h2>
            <p className="text-xl text-gray-600">Powerful features that make tracking a breeze</p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            <div className="feature-card group">
              <div className="feature-icon-container">
                <Zap className="w-6 h-6 text-white relative z-10" />
              </div>
              <h3 className="feature-title">Pick Your Top Competitors</h3>
              <p className="feature-description">Choose competitors you most often compete with in your sales process</p>
            </div>
            
            <div className="feature-card group">
              <div className="feature-icon-container">
                <Shield className="w-6 h-6 text-white relative z-10" />
              </div>
              <h3 className="feature-title">Choose Who to Monitor</h3>
              <p className="feature-description">From your chosen competitors, pick the connections you'd like to track</p>
            </div>
            
            <div className="feature-card group">
              <div className="feature-icon-container">
                <Sparkles className="w-6 h-6 text-white relative z-10" />
              </div>
              <h3 className="feature-title">Real-time Insights</h3>
              <p className="feature-description">Receive real-time Slack updates detailing the new LinkedIn connections of the individuals you're monitoring, empowering you to stay ahead of your competition</p>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div id="testimonials" className="relative py-20 overflow-hidden">
        <div className="absolute inset-0 bg-white"></div>
        <div className="max-w-7xl mx-auto px-4 relative z-10">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Loved by our Customers</h2>
            <p className="text-xl text-gray-600">See what our users have to say about truepipe.ai</p>
          </div>

          <div className="relative">
            {/* Scrolling Container */}
            <div className="flex gap-8 testimonial-scroll">
              {/* Original testimonials */}
              {[1, 2].map(() => (
                <>
                  {/* Testimonial 1 */}
                  <div className="testimonial-card">
                    <Quote className="quote-mark text-purple-600/20 w-8 h-8 -left-2 -top-2" />
                    <div className="relative z-10">
                      <p className="text-gray-600 mb-6 italic">
                        "truepipe.ai has transformed our sales intelligence. We've identified 40% more opportunities by tracking our competitors' activities. The Slack integration is seamless!"
                      </p>
                      <div className="flex items-center gap-4">
                        <div className="relative">
                          <img
                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=100&h=100&fit=crop&crop=face"
                            alt="Sarah Chen"
                            className="w-12 h-12 rounded-full object-cover"
                          />
                          <div className="avatar-ring"></div>
                        </div>
                        <div>
                          <h4 className="font-semibold text-gray-900">Sarah Chen</h4>
                          <p className="text-sm text-gray-500">VP Sales @ TechScale</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Testimonial 2 */}
                  <div className="testimonial-card">
                    <Quote className="quote-mark text-purple-600/20 w-8 h-8 -left-2 -top-2" />
                    <div className="relative z-10">
                      <p className="text-gray-600 mb-6 italic">
                        "The real-time notifications about competitor activities have been a game-changer. We've increased our win rate by 25% since implementing truepipe.ai."
                      </p>
                      <div className="flex items-center gap-4">
                        <div className="relative">
                          <img
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=100&h=100&fit=crop&crop=face"
                            alt="Mark Thompson"
                            className="w-12 h-12 rounded-full object-cover"
                          />
                          <div className="avatar-ring"></div>
                        </div>
                        <div>
                          <h4 className="font-semibold text-gray-900">Mark Thompson</h4>
                          <p className="text-sm text-gray-500">Sales Director @ GrowthLabs</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Testimonial 3 */}
                  <div className="testimonial-card">
                    <Quote className="quote-mark text-purple-600/20 w-8 h-8 -left-2 -top-2" />
                    <div className="relative z-10">
                      <p className="text-gray-600 mb-6 italic">
                        "Being able to track our top competitors' sales activities has given us incredible insights. The daily reports help us stay ahead of the competition."
                      </p>
                      <div className="flex items-center gap-4">
                        <div className="relative">
                          <img
                            src="https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?w=100&h=100&fit=crop&crop=face"
                            alt="Emily Rodriguez"
                            className="w-12 h-12 rounded-full object-cover"
                          />
                          <div className="avatar-ring"></div>
                        </div>
                        <div>
                          <h4 className="font-semibold text-gray-900">Emily Rodriguez</h4>
                          <p className="text-sm text-gray-500">Sales Ops @ SaaS Leaders</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>

            {/* Gradient Fade Edges */}
            <div className="pointer-events-none absolute inset-y-0 left-0 w-32 bg-gradient-to-r from-white to-transparent"></div>
            <div className="pointer-events-none absolute inset-y-0 right-0 w-32 bg-gradient-to-l from-white to-transparent"></div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-20 bg-white">
        <div className="max-w-3xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Frequently Asked Questions</h2>
            <p className="text-xl text-gray-600">Everything you need to know about truepipe.ai</p>
          </div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="glass-effect rounded-xl overflow-hidden premium-shadow"
              >
                <button
                  className="w-full px-6 py-4 flex items-center justify-between text-left"
                  onClick={() => setOpenFaq(openFaq === index ? null : index)}
                >
                  <span className="font-medium text-gray-900">{faq.question}</span>
                  {openFaq === index ? (
                    <ChevronUp className="w-5 h-5 text-gray-500" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-gray-500" />
                  )}
                </button>
                {openFaq === index && (
                  <div className="px-6 pb-4">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div id="pricing" className="py-20 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Simple, Transparent Pricing</h2>
            <p className="text-xl text-gray-600">Choose the plan that's right for your team</p>
          </div>
          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {pricingPlans.map((plan, index) => (
              <div key={index} className={`relative ${plan.popular ? 'scale-105' : ''}`}>
                {plan.popular && (
                  <div className="absolute -top-4 left-0 right-0 text-center">
                    <span className="bg-gradient-to-r from-purple-600 to-blue-600 text-white px-4 py-1 rounded-full text-sm font-medium">
                      Most Popular
                    </span>
                  </div>
                )}
                <div className={`h-full glass-effect p-8 rounded-2xl premium-shadow ${plan.popular ? 'border-2 border-purple-600' : ''}`}>
                  <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                  <div className="mb-4">
                    <span className={`${plan.period ? 'text-4xl' : 'text-3xl'} font-bold`}>{plan.price}</span>
                    {plan.period && <span className="text-gray-600">{plan.period}</span>}
                  </div>
                  <p className="text-gray-600 mb-6">{plan.description}</p>
                  <ul className="space-y-3 mb-8">
                    {plan.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center gap-2">
                        <Check className="w-5 h-5 text-purple-600 flex-shrink-0" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>
                  {plan.name === "Enterprise" ? (
                    <a 
                      href="https://calendly.com/truepipe/30min"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`block w-full py-3 rounded-lg font-medium text-center transition-all ${
                        plan.popular
                          ? 'bg-gradient-to-r from-purple-600 to-blue-600 text-white hover:opacity-90'
                          : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                      }`}
                    >
                      {plan.cta}
                    </a>
                  ) : (
                    <button 
                      onClick={() => {
                        document.getElementById('email').focus();
                        document.getElementById('email').scrollIntoView({ behavior: 'smooth' });
                      }}
                      className={`w-full py-3 rounded-lg font-medium transition-all ${
                        plan.popular
                          ? 'bg-gradient-to-r from-purple-600 to-blue-600 text-white hover:opacity-90'
                          : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                      }`}
                    >
                      7-Day Free Trial
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Ready to Get Started Section */}
      <div className="bg-gradient-to-r from-purple-600 to-blue-600 text-white py-20">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-4">Ready to Get Started?</h2>
          <p className="text-xl mb-8">Join hundreds of businesses already using our platform</p>
          <a 
            href="https://calendly.com/tim-truepipe"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-8 py-4 bg-gradient-to-r from-white to-gray-50 text-blue-600 rounded-lg font-medium hover:opacity-90 transition-opacity"
          >
            Talk to Sales
          </a>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-white border-t border-gray-100">
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <div className="flex items-center gap-2 mb-4">
                <Target className="w-6 h-6 text-purple-600" />
                <span className="font-bold text-gray-900">truepipe.ai</span>
              </div>
              <p className="text-gray-600">The future of competitive intelligence.</p>
            </div>
            
            <div>
              <h4 className="font-bold mb-4">Product</h4>
              <ul className="space-y-2">
                <li><a href="#features" className="text-gray-600 hover:text-gray-900">Features</a></li>
                <li><a href="#pricing" className="text-gray-600 hover:text-gray-900">Pricing</a></li>
                <li><a href="#download" className="text-gray-600 hover:text-gray-900">Download</a></li>
              </ul>
            </div>
            
            <div>
              <h4 className="font-bold mb-4">Company</h4>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-600 hover:text-gray-900">About</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Blog</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Careers</a></li>
              </ul>
            </div>
            
            <div>
              <h4 className="font-bold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Privacy</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Terms</a></li>
                <li><a href="#" className="text-gray-600 hover:text-gray-900">Security</a></li>
              </ul>
            </div>
          </div>
          
          <div className="mt-8 pt-8 border-t border-gray-200 text-center text-gray-600">
            <p>&copy; 2024 truepipe.ai. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default HomepageV4;